import { IUser } from "../../db/models"
import fetchPartnerService from "../../service/fetchPartnerService"

async function getUserById(id: string) {
  const res = await fetchPartnerService(
    "POST",
    {
      id,
    },
    "general/getUserById"
  )
  return res as IUser
}

async function updateUser(userId: string, data: Partial<IUser>) {
  const res = await fetchPartnerService(
    "PATCH",
    {
      userId,
      ...data,
    },
    `quoteComponent/updateUser`
  )

  return res
}

async function getUsersByAttribute(attribute: string, value: string) {
  const res = await fetchPartnerService(
    "POST",
    { attribute, value },
    "general/getUsersByAttribute"
  )

  const users = res as IUser[]
  return users
}

export { getUserById, updateUser, getUsersByAttribute }
